<template>
  <div
    class="floating-icon"
    @click="handleClick"
    v-if="serviceUrl != '' && serviceUrl != null"
  >
    <img src="../assets/img/contact_green_icon.png" />
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
const { state } = useStore();
import { api_getOption } from "@/request/api";

const serviceUrl = ref("");

api_getOption({}).then((res) => {
  if (res.code == 200) {
    serviceUrl.value = res.data.recharge_service_link;
  }
});

function handleClick() {
  window.open(serviceUrl.value);
}
</script>

<style lang="less">
.floating-icon {
  position: fixed;
  bottom: 11%;
  right: 6.5%;
  width: 80px;
  height: 80px;
  // background: linear-gradient(to bottom, rgb(90, 136, 253), rgb(0, 206, 188));
  background-color: white;
  // color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background 0.3s;
  border: 2px solid #10dbd5;
  z-index: 99;

  img {
    width: 50px;
    height: 50px;
    color: white;
  }
}

// .floating-icon:hover {
//   // background-color: #0ebbb5;
// }
</style>
