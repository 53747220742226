<template>
  <div class="title_layout_content" v-if="state.is_WEB == true">
    <img
      class="title_back"
      src="../assets/img/back.png"
      @click="router.back()"
    />
    <div class="title_layout_bar">
      <!-- <img
        v-if="state.is_D == true && state.is_TK_LOGO == false"
        class="tk_mall_D"
        src="../assets/img/logo.jpg"
        @click="router.push('./')"
      />
      <img
        v-else
        class="tk_mall_D"
        src="../assets/img/tk_logo.png"
        @click="router.push('./')"
      /> -->
      <div class="lang" @click="showLangSelect = true">
        {{ langValue }}
        <van-icon name="arrow-down" size="24" color="#999999" />
      </div>
    </div>
  </div>

  <van-popup v-model:show="showLangSelect" round position="bottom">
    <van-picker
      :columns="langSelectList"
      @cancel="showLangSelect = false"
      @confirm="onConfirmLangSelect"
      :confirm-button-text="$t('_t_p_qr')"
      :cancel-button-text="$t('cancel')"
    />
  </van-popup>
</template>

<script setup>
import { useStore } from "vuex";
const { state } = useStore();
import { useRouter } from "vue-router";
import { ref } from "vue";

const router = useRouter();
const langValue = ref("");
const lang = ref(sessionStorage.getItem("lang"));
const langSelectList = ref([]);
const showLangSelect = ref(false);

getLangValue();
function getLangValue() {
  if (!sessionStorage.getItem("lang")) {
    sessionStorage.setItem("lang", "en");
  }
  let value = "";
  Array.from(state.countryLang).forEach((item) => {
    if (item["lang"] == sessionStorage.getItem("lang")) {
      value = item["value"];
      lang.value = item["lang"];
      langValue.value = value;
      sessionStorage.setItem("lang", lang.value);
    }
    let data = {
      text: item["value"],
      value: item["lang"],
    };
    langSelectList.value.push(data);
  });
  return value;
}

function onConfirmLangSelect(options) {
  showLangSelect.value = false;
  langValue.value = options.selectedOptions[0].text;
  lang.value = options.selectedOptions[0].value;
  sessionStorage.setItem("lang", lang.value);
  location.reload();
}
</script>

<style lang="less">
.title_layout_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 3%;
  background-color: white;

  .title_back {
    width: 50px;
  }

  .title_layout_bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .tk_mall_D {
      height: 110px;
      width: 110px;
      border-radius: 50%;
    }
    .lang {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 20px;
      font-size: 28px;
      color: #999999;
    }
  }
  .title_right_no_login {
    padding: 30px 15px;
    color: var(--btn-background-color);
    font-size: 28px;
    padding: 20px 0px;
    text-align: center;
  }
}

:deep(.van-popup--bottom.van-popup--round) {
  border-radius: 12px 12px 0 0;

  .van-popup__close-icon {
    color: rgb(39, 38, 54);
    font-size: 36px;
    top: 36px;
  }
}
</style>
