import { createI18n } from "vue-i18n";

// const is_WEB = true; // 当前分站是否是web版本的
// let url = location.href;
// let arr = [
//   "id",
//   "de",
//   "fr",
//   "th",
//   "zh",
//   "en",
//   "hk",
//   "ja",
//   "ko",
//   "russian",
//   "vi",
//   "zh-Hant",
//   "tw",
//   "bl",
//   "teq",
//   "ydl",
// ];
// if (is_WEB == false) {
//   if (
//     url.indexOf("?") != -1 &&
//     url.indexOf("data") != -1 &&
//     url.split("?")[1]
//   ) {
//     let res = url.split("?")[1].split("&")[1].split("=")[1];
//     let str = res.replace(/\s/g, "A");
//     let info = {};
//     try {
//       info = JSON.parse(atob(str));
//     } catch (error) {
//       info = decodeURIComponent(atob(str));
//       info = info.replace(/[^\x00-\x7F]/g, "").replace(/\u000c\u000f8</g, "");
//       info = JSON.parse(info);
//     }
//     let lang = info.lang;
//     if (lang == "zh-Hant" || lang == "tw") {
//       sessionStorage.setItem("lang", "hk");
//     } else {
//       sessionStorage.setItem("lang", lang);
//     }
//     console.log("lang :>> ", lang);
//     let type = arr.every((x) => x != lang);
//     if (type) {
//       sessionStorage.setItem("lang", "en");
//     }
//   } else {
//     if (!sessionStorage.getItem("lang")) {
//       sessionStorage.setItem("lang", "en");
//     }
//   }
// } else {
//   if (!sessionStorage.getItem("lang")) {
//     sessionStorage.setItem("lang", "en");
//   }
// }

if (!sessionStorage.getItem("lang")) {
  sessionStorage.setItem("lang", "en");
}

// 印尼语（印度尼西亚）: id
// 泰语（泰国）: th
// 法语: fr
// 德语: de
// 英文: en
// 繁体: hk
// 日本: ja
// 俄语: russian
// 韩国: ko
// 越南语: vi
// 波兰语: bl
// 土耳其语: teq
// 意大利语: ydl

import id from "./id";
import de from "./de";
import fr from "./fr";
import th from "./th";
import zh from "./zh";
import en from "./en";
import hk from "./hk";
import ja from "./jp";
import ko from "./kr";
import russian from "./russian";
import vi from "./van";
import bl from "./bl";
import teq from "./teq";
import ydl from "./ydl";

const i18n = createI18n({
  locale: sessionStorage.getItem("lang") || "en",
  messages: {
    id,
    de,
    fr,
    th,
    zh,
    en,
    hk,
    ja,
    ko,
    russian,
    vi,
    bl,
    teq,
    ydl,
  },
});

export default i18n;
